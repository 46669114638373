import React from 'react';
import {
  Responsive,
  Segment,
  Grid,
  Image,
  Header,
  Button,
} from 'semantic-ui-react';

import { graphql, StaticQuery } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import CallTodayModal from '../components/CallTodayModal';

const About = () => (
  <StaticQuery
    query={ImageQuery}
    render={data => (
      <Layout>
        <SEO
          title="About"
          keywords={['duck', 'hunt', 'Georgia', 'Hartwell', 'Richardson']}
        />
        <Responsive>
          <Segment placeholder style={{ padding: '4em 0em' }} vertical>
            <Grid container stackable verticalAlign="middle">
              <Grid.Row>
                <Grid.Column width={8}>
                  <Header as="h3" style={{ fontSize: '2em' }}>
                    Hospitality at it's finest
                  </Header>
                  <p style={{ fontSize: '1.33em' }}>
                    The Richardsons reside in beautiful north Georgia, a mile
                    from Lake Hartwell. The 100 acre farm has been in the family
                    for generations.
                  </p>
                  <Header as="h3" style={{ fontSize: '2em' }}>
                    Lodging and Food
                  </Header>
                  <p style={{ fontSize: '1.33em' }}>
                    The Richardsons are some of the finest cooks I know, and you
                    will think so, too, after you sample some of their food.
                    Much of it is grown or raised fresh on the farm itself!
                  </p>
                </Grid.Column>
                <Grid.Column floated="right" width={6}>
                  <Image
                    bordered
                    rounded
                    size="huge"
                    src={data.allFile.edges[0].node.publicURL}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column textAlign="center">
                  <CallTodayModal
                    trigger={<Button size="huge">Check Them Out</Button>}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Responsive>
      </Layout>
    )}
  />
);

const ImageQuery = graphql`
  {
    allFile(filter: { name: { eq: "stick-figure-temp" } }) {
      edges {
        node {
          publicURL
          name
          root
          dir
        }
      }
    }
  }
`;

export default About;
